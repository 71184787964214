type RouteKey = 'REPORT_DETAILED'
| 'REPORT_SUMMARY'
| 'CUSTOM_REPORT'
| 'CUSTOM_REPORT_AD'
| 'TIMESHEET_WEEK'
| 'DELETE_ACCOUNT'
| 'EXPENSES'
| 'EXPENSES_SETTINGS'
| 'REMOTE_WORK_SETTINGS'
| 'LOGIN'
| 'SIGN_UP'
| 'LOGOUT'
| 'TASK_REPORT'
| 'ADDON_JIRA_SETTINGS'
| 'ADDON_JIRA_ENABLE'
| 'ADDON_JIRA_DISABLE'
| 'TC2_REPORT_SUMMARY'
| 'TC2_REPORT_DETAILED'
| 'TC2_SUBSCRIPTION'
| 'TC2_ADDON_BILLING'
| 'TC2_TIMESHEET_TIMER'
| 'TC2_TIMESHEET_TIMER_SETTINGS'
| 'TC2_TIMESHEET_WEEK'
| 'TC2_TIMESHEET_CALENDAR'
| 'TC_USERS_WITH_MODAL'
| 'TC2_ENTRIES_HISTORY'
| 'TC2_ENTRIES_REPORT'
| 'TC2_DELETE_ACCOUNT'
| 'TC2_KIOSK_ADDON_SETTINGS'
| 'TC_KB_BILLING'
| 'TC_KB_DELETE_ACCOUNT_SUBSCRIPTION'
| 'TC2_PROJECTS'
| 'TC2_MODULES'
| 'TC2_SETTINGS'
| 'TC2_PROFILE'
| 'TC2_ATTENDANCE'
| 'TC2_ATTENDANCE_SETTINGS'
| 'TC2_INVOICES'
| 'TC2_INVOICES_NEW'
| 'TC2_INVOICES_SETTINGS'
| 'TC2_APPROVALS'
| 'TC2_APPROVALS_SETTINGS'
| 'TC_HELP_AI'
| 'TC_HELP_TIME_TRACKING'
| 'TC_HELP_ATTENDANCE'
| 'TC_HELP_GEOFENCING'
| 'TC_HELP_COMPUTER_TIME_BASICS'
| 'TC_HELP_TAGS'
| 'TC_HELP_APPROVALS'
| 'TC_HELP_INVOICING'
| 'TC_HELP_BILLING'
| 'TC_HELP_LOCATION_TRACKING'
| 'TC_HELP_NOTION'
| 'TC2_ADDON_NOTION_ENABLE_OAUTH'
| 'TC2_ADDON_NOTION_DISABLE'
| 'TC2_ADDON_NOTION_ENABLE'
| 'TC2_ADDON_SALESFORCE_ENABLE'
| 'TC2_ADDON_SALESFORCE_DISABLE'
| 'TC2_ADDON_SALESFORCE_FORM'
| 'TC_HELP_SALESFORCE'
| 'TIMECAMP_ORDER_LINK'
| 'TC2_INTEGRATIONS'
| 'TC2_MARKET_PLACE'
| 'TC2_PLUGINS'
| 'TC2_HELP_CENTER'
| 'TC2_CONTACT_CENTER'
| 'TC2_TERMS'
| 'TC2_API_DOCS'
| 'TC2_SUGGEST_FEATURE'
| 'TC_USERS'
| 'TC_HELP_JIRA'
| 'DOWNLOAD_BROWSER_PLUGIN_CHROME'
| 'DOWNLOAD_BROWSER_PLUGIN_EDGE'
| 'JIRA_PLUGIN'
| 'FORGOTTEN_PASSWORD'
| 'TC2_POLICY'
| 'TC_AUTH_GOOGLE'
| 'TC_AUTH_APPLE'
| 'TC_AUTH_SSO'
;

export type IRoutes = {
  [K in RouteKey]: {
    path: string;
    type: ROUTE_TYPES;
    url: (params?: Record<string, any>) => string;
  };
};

export const enum ROUTE_TYPES {
  INTERNAL_REACT,
  INTERNAL_TC2,
  EXTERNAL,
  MARKETING,
}

export type Route = {
  path: string;
  type: ROUTE_TYPES;
  url: () => string;
};
