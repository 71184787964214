import type { IPlans } from '@/types/IPlans';
import { config } from '@/config';
import { ROUTE_TYPES, type Route, type IRoutes } from './routes.types';

const getRoute = (route: Route, isAppPrefix = false): string => {
  if ([ROUTE_TYPES.INTERNAL_TC2, ROUTE_TYPES.INTERNAL_REACT].includes(route.type)) {
    return `${isAppPrefix ? '/app#' : ''}${route.path}`;
  }

  if (route.type === ROUTE_TYPES.MARKETING) {
    return `${config.helpCenterPageUrl}${route.path}`;
  }

  return route.path;
};

export const routes: IRoutes = {
  REPORT_DETAILED: {
    path: '/report/detailed',
    type: ROUTE_TYPES.INTERNAL_REACT,
    url: () => getRoute(routes.REPORT_DETAILED, true),
  },
  REPORT_SUMMARY: {
    path: '/report/summary',
    type: ROUTE_TYPES.INTERNAL_REACT,
    url: () => getRoute(routes.REPORT_SUMMARY, true),
  },
  CUSTOM_REPORT: {
    path: '/report/custom/:slug',
    type: ROUTE_TYPES.INTERNAL_REACT,
    url: () => getRoute(routes.CUSTOM_REPORT, true),
  },
  CUSTOM_REPORT_AD: {
    path: '/report/custom',
    type: ROUTE_TYPES.INTERNAL_REACT,
    url: () => getRoute(routes.CUSTOM_REPORT, true),
  },
  TIMESHEET_WEEK: {
    path: '/timesheets/week',
    type: ROUTE_TYPES.INTERNAL_REACT,
    url: () => getRoute(routes.TIMESHEET_WEEK),
  },
  DELETE_ACCOUNT: {
    path: '/groups/cancel',
    type: ROUTE_TYPES.INTERNAL_REACT,
    url: () => getRoute(routes.DELETE_ACCOUNT),
  },
  EXPENSES: {
    path: '/expenses',
    type: ROUTE_TYPES.INTERNAL_REACT,
    url: () => getRoute(routes.EXPENSES),
  },
  EXPENSES_SETTINGS: {
    path: '/settings/addons/expenses',
    type: ROUTE_TYPES.INTERNAL_REACT,
    url: () => getRoute(routes.EXPENSES_SETTINGS),
  },
  REMOTE_WORK_SETTINGS: {
    path: '/settings/addons/remote-work',
    type: ROUTE_TYPES.INTERNAL_REACT,
    url: () => getRoute(routes.REMOTE_WORK_SETTINGS),
  },
  LOGIN: {
    path: '/auth/login',
    type: ROUTE_TYPES.INTERNAL_REACT,
    url: () => getRoute(routes.LOGIN),
  },
  SIGN_UP: {
    path: '/auth/register',
    type: ROUTE_TYPES.INTERNAL_REACT,
    url: () => getRoute(routes.SIGN_UP),
  },
  FORGOTTEN_PASSWORD: {
    path: '/auth/forgotten_password',
    type: ROUTE_TYPES.INTERNAL_REACT,
    url: () => getRoute(routes.FORGOTTEN_PASSWORD),
  },
  LOGOUT: {
    path: '/auth/logout',
    type: ROUTE_TYPES.EXTERNAL,
    url: () => getRoute(routes.LOGOUT),
  },
  TASK_REPORT: {
    path: '/time_tracking/task_report',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: ({ taskId }: { taskId: number | string }) => `${getRoute(routes.TASK_REPORT)}/${taskId}`,
  },
  ADDON_JIRA_SETTINGS: {
    path: '/addons/jira_v2',
    type: ROUTE_TYPES.INTERNAL_REACT,
    url: () => getRoute(routes.ADDON_JIRA_SETTINGS),
  },
  ADDON_JIRA_ENABLE: {
    path: '/addons/jira_v2/enable',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.ADDON_JIRA_ENABLE),
  },
  ADDON_JIRA_DISABLE: {
    path: '/addons/jira_v2/disable',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.ADDON_JIRA_DISABLE),
  },
  TC_HELP_JIRA: {
    path: '/jira',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_HELP_JIRA),
  },
  TC2_REPORT_SUMMARY: {
    path: '/reports/projects_and_tasks/projectsAndTasks',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_REPORT_SUMMARY, true),
  },
  TC2_REPORT_DETAILED: {
    path: '/reports/projects_and_tasks/detailed',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_REPORT_DETAILED, true),
  },
  TC2_SUBSCRIPTION: {
    path: '/app#/subscription',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: (params?: {
      plan?: IPlans
    }) => {
      let url = getRoute(routes.TC2_SUBSCRIPTION);

      if (params) {
        url += `?${new URLSearchParams(params).toString()}`;
      }

      return url;
    },
  },
  TC2_ADDON_BILLING: {
    path: '/app#/settings/addons/billing',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_ADDON_BILLING),
  },
  TC2_TIMESHEET_TIMER: {
    path: '/app#/timesheets/timer',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_TIMESHEET_TIMER),
  },
  TC2_TIMESHEET_TIMER_SETTINGS: {
    path: '/app#/settings/addons/timesheets',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_TIMESHEET_TIMER_SETTINGS),
  },
  TC2_TIMESHEET_WEEK: {
    path: '/app#/timesheets/week',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_TIMESHEET_WEEK),
  },
  TC2_TIMESHEET_CALENDAR: {
    path: '/app#/timesheets/graphical',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_TIMESHEET_CALENDAR),
  },
  TC_USERS: {
    path: 'app#/settings/users',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC_USERS),
  },
  TC_USERS_WITH_MODAL: {
    path: '/app#/settings/users?addUserModal',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC_USERS),
  },
  TC2_ENTRIES_HISTORY: {
    path: '/time_tracking/entries_history',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: ({ userId, entryDate, entryId }: {
      userId: number | string,
      entryDate: string,
      entryId: number | string,
    }) => `${getRoute(routes.TC2_ENTRIES_HISTORY)}/${userId}/${entryDate}/${entryId}`,
  },
  TC2_ENTRIES_REPORT: {
    path: '/time_tracking/task_report',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: ({ taskId }: {
      taskId: number | string,
    }) => `${getRoute(routes.TC2_ENTRIES_REPORT)}/${taskId}`,
  },
  TC2_DELETE_ACCOUNT: {
    path: '/groups/cancel',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_DELETE_ACCOUNT),
  },
  TC2_KIOSK_ADDON_SETTINGS: {
    path: '/settings/addons/kiosk',
    type: ROUTE_TYPES.INTERNAL_REACT,
    url: () => getRoute(routes.TC2_KIOSK_ADDON_SETTINGS),
  },
  TC_KB_BILLING: {
    path: '/billing-basics',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_KB_BILLING),
  },
  TC_KB_DELETE_ACCOUNT_SUBSCRIPTION: {
    path: '/delete-account',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_KB_DELETE_ACCOUNT_SUBSCRIPTION),
  },
  TC2_PROJECTS: {
    path: '/time_tracking/manage',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_PROJECTS),
  },
  TC2_MODULES: {
    path: '/app#/settings/addons',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_MODULES),
  },
  TC2_SETTINGS: {
    path: '/app#/settings',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_SETTINGS),
  },
  TC2_PROFILE: {
    path: '/app#/settings/users/me',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_PROFILE),
  },
  TC2_ATTENDANCE: {
    path: '/attendance',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_ATTENDANCE),
  },
  TC2_ATTENDANCE_SETTINGS: {
    path: '/app#/settings/addons/attendance',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_ATTENDANCE_SETTINGS),
  },
  TC2_INVOICES: {
    path: '/app#/invoices',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_INVOICES),
  },
  TC2_INVOICES_NEW: {
    path: '/app#/invoices/new',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_INVOICES_NEW),
  },
  TC2_INVOICES_SETTINGS: {
    path: '/app#/settings/addons/invoicing',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_INVOICES_SETTINGS),
  },
  TC2_APPROVALS: {
    path: '/app#/approval',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_APPROVALS),
  },
  TC2_APPROVALS_SETTINGS: {
    path: '/app#/settings/addons/approvals',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_APPROVALS_SETTINGS),
  },
  TC_HELP_AI: {
    path: '/timesheet-assistant',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_HELP_AI),
  },
  TC_HELP_TIME_TRACKING: {
    path: '/projects-basics',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_HELP_TIME_TRACKING),
  },
  TC_HELP_ATTENDANCE: {
    path: '/attendance-basics',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_HELP_ATTENDANCE),
  },
  TC_HELP_GEOFENCING: {
    path: '/geofencing',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_HELP_GEOFENCING),
  },
  TC_HELP_COMPUTER_TIME_BASICS: {
    path: '/computer-time-basics',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_HELP_COMPUTER_TIME_BASICS),
  },
  TC_HELP_TAGS: {
    path: '/tags-basics',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_HELP_TAGS),
  },
  TC_HELP_APPROVALS: {
    path: '/approvals-basics',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_HELP_APPROVALS),
  },
  TC_HELP_INVOICING: {
    path: '/invoicing-basics',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_HELP_INVOICING),
  },
  TC_HELP_BILLING: {
    path: '/invoicing-basics',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_HELP_BILLING),
  },
  TC_HELP_LOCATION_TRACKING: {
    path: '/gps',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_HELP_LOCATION_TRACKING),
  },
  TC_HELP_NOTION: {
    path: '/notion',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_HELP_NOTION),
  },
  TC2_ADDON_NOTION_ENABLE_OAUTH: {
    path: '/addons/notion/enableOAuth2',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_ADDON_NOTION_ENABLE_OAUTH),
  },
  TC2_ADDON_NOTION_DISABLE: {
    path: '/addons/notion/disable',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_ADDON_NOTION_DISABLE),
  },
  TC2_ADDON_NOTION_ENABLE: {
    path: '/addons/notion/enable',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_ADDON_NOTION_ENABLE),
  },
  TC2_ADDON_SALESFORCE_ENABLE: {
    path: '/addons/salesforce/enable',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_ADDON_SALESFORCE_ENABLE),
  },
  TC2_ADDON_SALESFORCE_DISABLE: {
    path: '/addons/salesforce/disable',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_ADDON_SALESFORCE_DISABLE),
  },
  TC2_ADDON_SALESFORCE_FORM: {
    path: '/addons/salesforce/editItemsToSync',
    type: ROUTE_TYPES.EXTERNAL,
    url: () => getRoute(routes.TC2_ADDON_SALESFORCE_FORM),
  },
  TC_HELP_SALESFORCE: {
    path: '/salesforce-react',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC_HELP_SALESFORCE),
  },
  TC_AUTH_GOOGLE: {
    path: '/auth/oauth/google/register',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC_AUTH_GOOGLE),
  },
  TC_AUTH_APPLE: {
    path: '/auth/oauth/apple/register',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC_AUTH_APPLE),
  },
  TC_AUTH_SSO: {
    path: '/saml/auth',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC_AUTH_SSO),
  },
  TIMECAMP_ORDER_LINK: {
    path: 'https://calendly.com/timecamp-success/custom-reporting-in-timecamp-discovery-call',
    type: ROUTE_TYPES.EXTERNAL,
    url: () => getRoute(routes.TIMECAMP_ORDER_LINK),
  },
  TC2_INTEGRATIONS: {
    path: '/app#/settings/integrations',
    type: ROUTE_TYPES.INTERNAL_TC2,
    url: () => getRoute(routes.TC2_INTEGRATIONS),
  },
  TC2_MARKET_PLACE: {
    path: 'https://www.timecamp.com/partners-marketplace?utm_source=app&utm_medium=integrations&utm_campaign=integrations&_gl=1*m79ywq*_gcl_aw*R0NMLjE2NjI4OTExODEuRUFJYUlRb2JDaE1JMjVfaHJzQ00tZ0lWV3k0WUNoM2NrQXI4RUFFWUFTQUFFZ0pJb3ZEX0J3RQ..',
    type: ROUTE_TYPES.EXTERNAL,
    url: () => getRoute(routes.TC2_MARKET_PLACE),
  },
  TC2_PLUGINS: {
    path: 'https://www.timecamp.com/applications/',
    type: ROUTE_TYPES.EXTERNAL,
    url: () => getRoute(routes.TC2_PLUGINS),
  },
  TC2_HELP_CENTER: {
    path: '',
    type: ROUTE_TYPES.MARKETING,
    url: () => getRoute(routes.TC2_HELP_CENTER),
  },
  TC2_CONTACT_CENTER: {
    path: 'https://www.timecamp.com/contact',
    type: ROUTE_TYPES.EXTERNAL,
    url: () => getRoute(routes.TC2_CONTACT_CENTER),
  },
  TC2_TERMS: {
    path: 'https://www.timecamp.com/terms-conditions',
    type: ROUTE_TYPES.EXTERNAL,
    url: () => getRoute(routes.TC2_TERMS),
  },
  TC2_POLICY: {
    path: 'https://www.timecamp.com/privacy-policy',
    type: ROUTE_TYPES.EXTERNAL,
    url: () => getRoute(routes.TC2_POLICY),
  },
  TC2_API_DOCS: {
    path: 'https://developer.timecamp.com',
    type: ROUTE_TYPES.EXTERNAL,
    url: () => getRoute(routes.TC2_API_DOCS),
  },
  TC2_SUGGEST_FEATURE: {
    path: 'https://timecamp.canny.io',
    type: ROUTE_TYPES.EXTERNAL,
    url: () => getRoute(routes.TC2_SUGGEST_FEATURE),
  },
  DOWNLOAD_BROWSER_PLUGIN_CHROME: {
    path: 'https://chromewebstore.google.com/detail/time-tracker-by-timecamp/ohbkdjmhoegleofcohdjagmcnkimfda',
    type: ROUTE_TYPES.EXTERNAL,
    url: () => getRoute(routes.DOWNLOAD_BROWSER_PLUGIN_CHROME),
  },
  DOWNLOAD_BROWSER_PLUGIN_EDGE: {
    path: 'https://microsoftedge.microsoft.com/addons/detail/time-tracker-by-timecamp/oipghnhfamneifpnkiefiikfklofhjcp',
    type: ROUTE_TYPES.EXTERNAL,
    url: () => getRoute(routes.DOWNLOAD_BROWSER_PLUGIN_EDGE),
  },
  JIRA_PLUGIN: {
    path: 'https://marketplace.atlassian.com/apps/1219229/timecamp-time-tracking',
    type: ROUTE_TYPES.EXTERNAL,
    url: () => getRoute(routes.JIRA_PLUGIN),
  },
} as const;
